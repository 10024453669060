import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { STATUS } from 'utils/status';

// Fetch blogs
export const fetchBlogs = createAsyncThunk('blogs/fetchBlogs', async () => {
	const response = await axios.get(
		`${process.env.REACT_APP_BACKEND_URL}/api/getAllBlog`
	);
	return response?.data?.blogs;
});

// Delete blog
export const deleteBlog = createAsyncThunk(
	'blogs/deleteBlog',
	async (blogId) => {
		await axios.delete(
			`${process.env.REACT_APP_BACKEND_URL}/api/deleteBlog/${blogId}`
		);
		return blogId;
	}
);

// Update blog
export const updateBlog = createAsyncThunk(
	'blogs/updateBlog',
	async ({ blogId, blogData }) => {
		const response = await axios.put(
			`${process.env.REACT_APP_BACKEND_URL}/api/updateBlog/${blogId}`,
			blogData
		);
		return response?.data?.blog;
	}
);

// Fetch blog by ID
export const fetchBlogById = createAsyncThunk(
	'blogs/fetchBlogById',
	async (blogId) => {
		const response = await axios.get(
			`${process.env.REACT_APP_BACKEND_URL}/api/getOneBlog/${blogId}`
		);
		return response?.data?.BlogExist;
	}
);

// Update blog post status
export const updatePostStatus = createAsyncThunk(
	'blogs/updatePostStatus',
	async ({ postId, status }, thunkAPI) => {
		try {
			const response = await axios.put(
				`${process.env.REACT_APP_BACKEND_URL}/api/updateBlogStatus/${postId}`,
				{ status }
			);
			return { id: postId, status: response.data.blog.status };
		} catch (error) {
			return thunkAPI.rejectWithValue(error.response.data);
		}
	}
);

const blogSlice = createSlice({
	name: 'blog',
	initialState: {
		data: {
			blogs: []
		},
		status: STATUS.IDLE,
		error: null,
		message: '' // Add a field for the message or text you want to display
	},
	reducers: {},
	extraReducers: (builder) => {
		builder
			// Fetch blogs
			.addCase(fetchBlogs.fulfilled, (state, action) => {
				state.status = STATUS.SUCCEEDED;
				state.data.blogs = action.payload;
				state.message = 'Blogs fetched successfully!';
			})
			// Delete blog
			.addCase(deleteBlog.fulfilled, (state, action) => {
				state.status = STATUS.SUCCEEDED;
				state.data.blogs = state.data.blogs.filter(
					(blog) => blog._id !== action.payload
				);
				state.message = 'Blog deleted successfully!';
			})
			// Update blog
			.addCase(updateBlog.fulfilled, (state, action) => {
				state.status = STATUS.SUCCEEDED;
				const index = state.data.blogs.findIndex(
					(blog) => blog._id === action.payload._id
				);
				if (index !== -1) {
					state.data.blogs[index] = action.payload;
				}
				state.message = 'Blog updated successfully!';
			})
			/// Fetch blog by ID
			.addCase(fetchBlogById.fulfilled, (state, action) => {
				state.status = STATUS.SUCCEEDED;
				const blogIndex = state.data.blogs.findIndex(
					(blog) => blog._id === action.payload._id
				);
				if (blogIndex !== -1) {
					// Update existing blog in the list
					state.data.blogs[blogIndex] = action.payload;
				} else {
					// Add the fetched blog to the list if it's not already present
					state.data.blogs.push(action.payload);
				}
				state.message = 'Blog fetched successfully!';
			})
			// Update post status
			.addCase(updatePostStatus.fulfilled, (state, action) => {
				state.status = STATUS.SUCCEEDED;
				const index = state.data.blogs.findIndex(
					(blog) => blog._id === action.payload.id
				);
				if (index !== -1) {
					state.data.blogs[index].status = action.payload.status;
				}
				state.message = `Blog status updated to ${action.payload.status}!`;
			})
			.addCase(updatePostStatus.rejected, (state, action) => {
				state.status = STATUS.FAILED;
				state.error = action.payload;
				state.message = 'Failed to update blog status!';
			});
	}
});

export default blogSlice.reducer;
