import React, {
	useEffect,
	useState,
	useCallback,
	useRef,
	useMemo,
	lazy,
	Suspense
} from 'react';
import { debounce } from 'lodash';

// Lazy load JoditEditor to avoid issues with SSR
const JoditEditor = lazy(() => import('jodit-pro-react'));

const EditorComponent = React.memo(({ value, onChange, keyProp }) => {
	const [editorValue, setEditorValue] = useState(value);
	const editorRef = useRef(null);

	const debouncedOnChange = useRef(
		debounce((newContent) => {
			onChange(newContent);
		}, 500)
	).current;

	useEffect(() => {
		if (editorValue !== value) {
			setEditorValue(value);
		}
	}, [value]);

	const handleChange = useCallback(
		(newContent) => {
			setEditorValue(newContent);
			debouncedOnChange(newContent);
		},
		[debouncedOnChange]
	);

	const editorConfig = useMemo(
		() => ({
			zIndex: 0,
			readonly: false,
			activeButtonsInReadOnly: ['source', 'fullsize', 'print', 'about', 'dots'],
			toolbarButtonSize: 'middle',
			theme: 'default',
			saveModeInCookie: false,
			spellcheck: true,
			editorCssClass: false,
			triggerChangeEvent: true,
			width: 'auto',
			height: 'auto',
			minHeight: 100,
			language: 'auto',
			i18n: 'en',
			tabIndex: -1,
			toolbar: true,
			enter: 'P',
			useSplitMode: false,
			colorPickerDefaultTab: 'background',
			imageDefaultWidth: 300,
			removeButtons: [],
			disablePlugins: [],
			extraButtons: [],
			sizeLG: 900,
			sizeMD: 700,
			sizeSM: 400,
			buttons: [
				'bold',
				'strikethrough',
				'underline',
				'italic',
				'|',
				'ul',
				'ol',
				'|',
				'outdent',
				'indent',
				'|',
				'font',
				'fontsize',
				'brush',
				'paragraph',
				'|',
				'image',
				'video',
				'table',
				'link',
				'|',
				'align',
				'undo',
				'redo',
				'|',
				'hr',
				'eraser',
				'copyformat',
				'|',
				'symbols',
				'fullsize',
				'print',
				'superscript',
				'subscript',
				'|',
				'source',
				'|',
				'hr',
				'preview',
				'|',
				'spellcheck',
				'|',
				'pastecode',
				'emoji',
				'keyboard',
				'paste',
				'|'
			],
			events: {},
			textIcons: false
		}),
		[]
	);

	useEffect(() => {
		if (editorRef.current) {
			const editorInstance = editorRef.current.jodit;
			if (editorInstance) {
				editorInstance.focus();
			}
		}
	}, [editorRef]);

	return (
		<div className="editor-container">
			<Suspense fallback={<div>Loading editor...</div>}>
				<JoditEditor
					ref={editorRef}
					key={keyProp || 'editor'}
					value={editorValue}
					config={editorConfig}
					onChange={handleChange}
				/>
			</Suspense>
		</div>
	);
});

export default EditorComponent;
