// import node module libraries
import { Fragment, useEffect } from 'react';
import { Col, Row, Nav, Tab, Container, Card, Image } from 'react-bootstrap';
// import custom components
import PageHeadingBriefinfo from 'components/marketing/common/page-headings/PageHeadingBriefinfo';
import { CalendarCheck } from 'react-bootstrap-icons';
import UpcomingWebinarsData from 'data/marketing/landing-education/UpcomingWebinarsData';
import { Link } from 'react-router-dom';
import BlogSidebar from './BlogSidebar';
import { fetchBlogs } from 'slices/BlogSlice';
import { fetchCategories } from 'slices/categorySlice';
import { useSelector, useDispatch } from 'react-redux';
const EnvironmentsNews = () => {
	const dispatch = useDispatch();
	const { data: blogs, status: blogStatus } = useSelector(
		(state) => state?.blog
	);
	const categories = useSelector((state) => state?.category?.data?.categories);
	const blogsData = blogs?.blogs;
	const publishedBlogs = blogsData?.filter(
		(blog) => blog?.status === 'Published'
	);
	const reversedBlogs = publishedBlogs ? publishedBlogs.slice().reverse() : [];
	// const categoryIdToNameMap = categories.reduce((map, category) => {
	// 	map[category._id] = category.catName;
	// 	return map;
	// }, {});
	// const categoriesFromBlogs = reversedBlogs.map((item) => item.category);
	// const categoryNames = categoriesFromBlogs.map(categoryId => categoryIdToNameMap[categoryId]);

	// console.log(categoryNames, "categoryNames")
	// Create a mapping of category IDs to category names
	const categoryIdToNameMap = categories.reduce((map, category) => {
		map[category._id] = category.catName;
		return map;
	}, {});

	// Find the category ID for "National"
	const nationalCategoryId = Object.keys(categoryIdToNameMap).find(
		(id) => categoryIdToNameMap[id] === 'Environment'
	);

	// Filter the blog articles by the "National" category
	const filteredBlogs = reversedBlogs?.filter(
		(item) => item.category === nationalCategoryId
	);
	useEffect(() => {
		dispatch(fetchBlogs());
		dispatch(fetchCategories());
	}, []);
	const arrayBufferToBase64 = (buffer) => {
		let binary = '';
		const bytes = new Uint8Array(buffer);
		const len = bytes.byteLength;
		for (let i = 0; i < len; i++) {
			binary += String.fromCharCode(bytes[i]);
		}
		return window.btoa(binary);
	};
	return (
		<Fragment>
			{/* Page header */}
			<PageHeadingBriefinfo pagetitle="ENVIRONMENT NEWS" briefinfo="" />
			<section className="py-6 mx-4">
				<Row className="mb-6">
					<Col md={12}>
						<Tab.Container defaultActiveKey="mostpopular">
							<Nav className="nav-lb-tab">
								<Nav.Item className="ms-0">
									<Nav.Link eventKey="mostpopular" className="mb-sm-3 mb-md-0">
										Most Popular
									</Nav.Link>
								</Nav.Item>
								<Nav.Item>
									<Nav.Link eventKey="trending" className="mb-sm-3 mb-md-0">
										Trending
									</Nav.Link>
								</Nav.Item>
							</Nav>

							<Tab.Content>
								<Tab.Pane eventKey="mostpopular" className="pb-4 p-4 ps-0 pe-0">
									{/* most popular started */}
									<Row>
										{filteredBlogs?.slice(0, 6).map((item, index) => {
											const image = item?.thumbnail;
											const base64Image = arrayBufferToBase64(
												image?.data?.data
											);
											return (
												<Col key={index} lg={4} md={4} sm={6}>
													<div className="mb-xl-0 card-hover  my-4 rounded-0 ">
														<Card.Body classNmae="d-flex">
															<div>
																<Link
																	to={`/marketing/blog/article-single/${item._id}`}
																>
																	<div
																		style={{
																			width: '100%',
																			height: '250px',
																			overflow: 'hidden'
																		}}
																	>
																		<Image
																			src={`data:${item.contentType};base64,${base64Image}`}
																			alt="webinar-1"
																			className="w-100 h-100 img-fluid"
																			style={{ objectFit: 'cover' }}
																		/>
																	</div>
																</Link>
															</div>
															<div className="p-2">
																<div className="my-1 lh-1">
																	<span className="me-1">
																		<CalendarCheck size={14} />
																	</span>
																	<span>{item.date}</span>
																</div>
																<h3 className="mb-2 text-truncate py-2">
																	<Link
																		to={`/marketing/blog/article-single/${item._id}`}
																		className="text-inherit"
																	>
																		{item.postTitle}
																	</Link>
																</h3>
															</div>
														</Card.Body>
													</div>
												</Col>
											);
										})}
									</Row>
								</Tab.Pane>
								<Tab.Pane eventKey="trending" className="pb-4 p-4 ps-0 pe-0">
									<Row>
										{UpcomingWebinarsData.map((item, index) => {
											return (
												<Col key={index} lg={3} md={4} sm={6}>
													<Card className="mb-xl-0 card-hover  my-4 rounded-0 ">
														<Link
															to={`/marketing/blog/article-single/${item._id}`}
														>
															<div
																style={{
																	width: '100%',
																	height: '250px',
																	overflow: 'hidden'
																}}
															>
																<Image
																	src={item.image}
																	alt="webinar-1"
																	className="w-100 h-100 img-fluid"
																	style={{ objectFit: 'cover' }}
																/>
															</div>
														</Link>
														<Card.Body>
															<div className="my-1 lh-1">
																<span className="me-1">
																	<CalendarCheck size={14} />
																</span>
																<span>{item.date}</span>
															</div>
															<h3 className="mb-2 text-truncate">
																<Link to="#!" className="text-inherit">
																	{item.title}
																</Link>
															</h3>
														</Card.Body>
													</Card>
												</Col>
											);
										})}
									</Row>
								</Tab.Pane>
							</Tab.Content>
						</Tab.Container>
					</Col>
				</Row>
				<BlogSidebar filteredBlogs={filteredBlogs} />
			</section>
		</Fragment>
	);
};

export default EnvironmentsNews;
