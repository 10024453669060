import React, { Fragment, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Col, Row, Card, Form, Button, Image } from 'react-bootstrap';
import axios from 'axios';
import toast, { Toaster } from 'react-hot-toast';

const SignUp = () => {
	const [formData, setFormData] = useState({
		username: '',
		email: '',
		password: ''
	});

	const handleChange = (e) => {
		setFormData({ ...formData, [e.target.id]: e.target.value });
	};

	const navigate = useNavigate();

	const handleSubmit = async (e) => {
		e.preventDefault();
		try {
			const response = await axios.post(
				`${process.env.REACT_APP_BACKEND_URL}/api/register`,
				formData
			);
			if (response.status === 200) {
				toast.success('User registered successfully');
				navigate('/authentication/sign-in');
			} else {
				toast.error('Registration not successful!');
			}
		} catch (error) {
			console.error('Error registering user:', error);
			toast.error('Error registering user');
		}
	};

	const bgStyle = {
		backgroundImage:
			'url(https://www.indiafilings.com/learn/wp-content/uploads/2023/03/Where-can-I-register-a-firm-in-India.jpg)',
		backgroundSize: 'cover',
		backgroundPosition: 'center center',
		// width: '100vw',
		height: '100vh',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		paddingRight: '2rem'
		// margin:"auto"
	};

	const cardStyle = {
		backgroundColor: 'rgba(255, 255, 255, 0.75)',
		padding: '2rem',
		borderRadius: '10px',
		boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)'
	};

	return (
		<Fragment>
			<Toaster />
			<section style={bgStyle}>
				<Row className="w-100 h-100 d-flex align-items-center justify-content-end">
					<Col lg={5} md={6} className="py-8 py-xl-0">
						<Card style={cardStyle}>
							<Card.Body>
								{/* Form */}
								<Form onSubmit={handleSubmit}>
									<div className="mb-4">
										<h1 className="mb-1 fw-bold">Sign up</h1>
										<span>
											Already have an account?{' '}
											<Link to="/authentication/sign-in" className="ms-1">
												Login
											</Link>
										</span>
									</div>
									<Row>
										{/* User Name */}
										<Col lg={12} md={12} className="mb-3">
											<Form.Label> Name</Form.Label>
											<Form.Control
												type="text"
												id="name"
												placeholder=" Name"
												required
												onChange={handleChange}
											/>
										</Col>
										{/* email */}
										<Col lg={12} md={12} className="mb-3">
											<Form.Label>Email</Form.Label>
											<Form.Control
												type="email"
												id="email"
												placeholder="Email address here"
												required
												onChange={handleChange}
											/>
										</Col>
										{/* Password */}
										<Col lg={12} md={12} className="mb-3">
											<Form.Label>Password</Form.Label>
											<Form.Control
												type="password"
												id="password"
												placeholder="Enter Password"
												required
												onChange={handleChange}
											/>
										</Col>
										{/* Checkbox */}
										<Col lg={12} md={12} className="mb-3">
											<Form.Check type="checkbox" id="check-api-checkbox">
												<Form.Check.Input type="checkbox" />
												<Form.Check.Label>
													I agree to the{' '}
													<Link to="/pages/terms-and-conditions">
														Terms of Service{' '}
													</Link>{' '}
													and{' '}
													<Link to="/pages/terms-and-conditions">
														Privacy Policy.
													</Link>
												</Form.Check.Label>
											</Form.Check>
										</Col>
										<Col lg={12} md={12} className="mb-0 d-grid gap-2">
											{/* Button */}
											<Button
												variant="info"
												className="rounded-0"
												type="submit"
											>
												Register
											</Button>
										</Col>
									</Row>
								</Form>
							</Card.Body>
						</Card>
					</Col>
				</Row>
			</section>
		</Fragment>
	);
};

export default SignUp;
