import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { STATUS } from 'utils/status';

export const fetchCategories = createAsyncThunk(
	'categories/fetchCategories',
	async () => {
		const response = await axios.get(
			`${process.env.REACT_APP_BACKEND_URL}/api/getAllcategory`
		);
		return response?.data?.CategoryData;
	}
);

export const deleteCategory = createAsyncThunk(
	'categories/deleteCategory',
	async (categoryId) => {
		await axios.delete(
			`${process.env.REACT_APP_BACKEND_URL}/api/deleteCategory/${categoryId}`
		);
		return categoryId;
	}
);

export const updateCategory = createAsyncThunk(
	'categories/updateCategory',
	async ({ categoryId, categoryData }) => {
		const response = await axios.put(
			`${process.env.REACT_APP_BACKEND_URL}/api/updateCategory/${categoryId}`,
			categoryData
		);
		return response;
	}
);

const categorySlice = createSlice({
	name: 'category',
	initialState: {
		data: {
			categories: []
		},
		status: STATUS.IDLE,
		error: null
	},
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(fetchCategories.pending, (state) => {
				state.status = STATUS.LOADING;
			})
			.addCase(fetchCategories.fulfilled, (state, action) => {
				state.status = STATUS.SUCCEEDED;
				state.data.categories = action.payload;
			})
			.addCase(fetchCategories.rejected, (state, action) => {
				state.status = STATUS.FAILED;
				state.error = action.error.message;
			})
			.addCase(deleteCategory.fulfilled, (state, action) => {
				state.data.categories = state.data.categories.filter(
					(category) => category._id !== action.payload
				);
			})
			.addCase(updateCategory.fulfilled, (state, action) => {
				state.data.categories = state.data.categories.map((category) =>
					category._id === action.payload._id ? action.payload : category
				);
			});
	}
});

export default categorySlice.reducer;
