// import node module libraries
import React, { Fragment, useEffect, useCallback } from 'react';
import { Col, Row, Nav, Carousel, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';

// import sub components
import BlogCard from './BlogCard';
import BlogCardFullWidth from './BlogCardFullWidth';
import BuildingSkills from './BuildingSkills';
import LifeStyle from './lifestyle';
import NationalNews from './netionalNews';
import PortfolioList from '../pages/portfolio/PortfolioList';
import OtherNews from '../pages/portfolio/OtherNews';
import Education from '../pages/election/education';
import Business from '../pages/election/Business';
import Treding from '../pages/portfolio/treding';
import { useSelector, useDispatch } from 'react-redux';
import { fetchBlogs } from 'slices/BlogSlice';
import { fetchCategories } from 'slices/categorySlice';
const BlogListing = () => {
	// const dispatch = useDispatch();
	// const { data: blogs, status: blogStatus } = useSelector(
	// 	(state) => state?.blog
	// );
	// useEffect(() => {
	// 	dispatch(fetchBlogs());
	// 	dispatch(fetchCategories());
	// }, []);

	const dispatch = useDispatch();
	const { data: blogs, status: blogStatus } = useSelector(
		(state) => state.blog
	);
	const { status: categoryStatus } = useSelector((state) => state.category);

	const fetchData = useCallback(async () => {
		try {
			await Promise.all([dispatch(fetchBlogs()), dispatch(fetchCategories())]);
		} catch (error) {
			console.error('Failed to fetch data:', error);
		}
	}, [dispatch]);

	useEffect(() => {
		fetchData();
	}, []);

	const blogsData = blogs?.blogs;
	const publishedBlogs = blogsData?.filter(
		(blog) => blog?.status === 'Published'
	);
	const reversedBlogs = publishedBlogs ? publishedBlogs.slice().reverse() : [];
	const categories = useSelector((state) => state?.category?.data?.categories);
	// const categoryIdToNameMap = categories.reduce((map, category) => {
	// 	map[category._id] = category.catName;
	// 	return map;
	// }, {});
	// const categoriesFromBlogs = publishedBlogs.map((item) => item.category);
	// const categoryNames = categoriesFromBlogs.map(categoryId => categoryIdToNameMap[categoryId]);

	// console.log(categoryNames, "categoryNames")
	// Create a mapping of category IDs to category names
	const categoryIdToNameMap = categories?.reduce((map, category) => {
		map[category._id] = category.catName;
		return map;
	}, {});

	// Find the category ID for "National"
	const NationalCategoryId = Object.keys(categoryIdToNameMap).find(
		(id) => categoryIdToNameMap[id] === 'National'
	);
	const filteredNational = publishedBlogs?.filter(
		(item) => item.category === NationalCategoryId
	);
	//Education filter
	const EducationCategoryId = Object.keys(categoryIdToNameMap).find(
		(id) => categoryIdToNameMap[id] === 'Education'
	);
	const filteredEducation = publishedBlogs?.filter(
		(item) => item.category === EducationCategoryId
	);

	//Business
	const BusinessCategoryId = Object.keys(categoryIdToNameMap).find(
		(id) => categoryIdToNameMap[id] === 'Business'
	);
	const filteredBusiness = publishedBlogs?.filter(
		(item) => item.category === BusinessCategoryId
	);
	//Top stories and latest
	const TopStoriesCategoryId = Object.keys(categoryIdToNameMap).find(
		(id) => categoryIdToNameMap[id] === 'Top Stories'
	);
	const filteredTopStories = publishedBlogs?.filter(
		(item) => item.category === TopStoriesCategoryId
	);
	//Top stories and latest
	const LifeStyleCategoryId = Object.keys(categoryIdToNameMap).find(
		(id) => categoryIdToNameMap[id] === 'Life Style'
	);
	const filteredLifeStyle = publishedBlogs?.filter(
		(item) => item.category === LifeStyleCategoryId
	);

	const arrayBufferToBase64 = (buffer) => {
		let binary = '';
		const bytes = new Uint8Array(buffer);
		const len = bytes.byteLength;
		for (let i = 0; i < len; i++) {
			binary += String.fromCharCode(bytes[i]);
		}
		return window.btoa(binary);
	};
	if (!blogsData) {
		return <div>Loading...</div>;
	}
	return (
		<Fragment>
			{/* Content */}
			<section className="pb-8 mt-4 bg-white  ">
				<Row className="mx-4 ms-2">
					<Col lg={6} md={12}>
						<Carousel
							controls={true}
							indicators={false}
							className="align-items-center justify-content-center"
						>
							{reversedBlogs?.slice(1, 10).map((item, index) => {
								const image = item?.thumbnail;
								const base64Image = image
									? arrayBufferToBase64(image?.data?.data)
									: null;

								return (
									<Carousel.Item key={index}>
										<Link to={`/marketing/blog/article-single/${item._id}`}>
											{base64Image && (
												<Image
													className="d-block w-100"
													src={`data:${item.contentType};base64,${base64Image}`}
													alt={`Slide ${index + 1}`}
													style={{ height: '400px', objectFit: 'cover' }}
												/>
											)}
											<Carousel.Caption>
												<h3 className="text-danger">
													{item.postTitle || `Slide ${index + 1}`}
												</h3>
												<p>{item.description || 'No description available.'}</p>
											</Carousel.Caption>
										</Link>
									</Carousel.Item>
								);
							})}
						</Carousel>
					</Col>

					<Col lg={6} md={12}>
						<div>
							{reversedBlogs?.slice(0, 1).map((item, index) => {
								const image = item?.thumbnail;
								const base64Image = image
									? arrayBufferToBase64(image?.data?.data)
									: null;

								return (
									<div key={index}>
										<Link to={`/marketing/blog/article-single/${item._id}`}>
											{base64Image && (
												<Image
													style={{ height: '400px' }}
													className="d-block w-100"
													src={`data:${item.contentType};base64,${base64Image}`}
													alt={`Slide ${index + 1}`}
												/>
											)}
											<div>
												<h3 className="">{item.postTitle}</h3>
												{/* <p>{item.description || 'No description available.'}</p> */}
											</div>
										</Link>
									</div>
								);
							})}
						</div>
					</Col>
				</Row>
				<Row className="mx-4">
					<Col xl={12} lg={12} md={12} sm={12}>
						{/* Flush Nav */}
						<div className="flush-nav">
							<Nav>
								<Link
									to="/marketing/blog/listing/"
									className="ps-0 nav-link active"
								>
									ALL
								</Link>

								<Link to="/marketing/blog/category/" className="nav-link">
									LATEST
								</Link>
								<Link
									to="/marketing/blog/category/"
									className="nav-link uppercase"
								>
									INTERNATIONAL
								</Link>
								<Link to="/marketing/blog/category/" className="nav-link">
									SPORTS
								</Link>
								<Link to="/marketing/blog/category/" className="nav-link">
									EDUCATION
								</Link>
							</Nav>
						</div>
					</Col>
				</Row>
				<hr />
				<Row className="mx-4">
					{/* Show first article in full width  */}
					{/* {publishedBlogs.filter(function (dataSource) {
						return dataSource.id === 1;
					}). */}
					{publishedBlogs?.slice(5, 6).map((item, index) => (
						<Col xl={12} lg={12} md={12} sm={12} key={index}>
							<BlogCardFullWidth item={item} />
						</Col>
					))}

					{/* Show remaining articles in 3 column width  */}
					{publishedBlogs.slice(1, 17).map((item, index) => (
						<Col xl={3} lg={3} md={6} sm={12} key={index} className="d-flex">
							<BlogCard item={item} />
						</Col>
					))}
				</Row>
				<hr />
				<BuildingSkills data={filteredTopStories} />
				<hr />
				<NationalNews data={filteredNational} />

				<Education data={filteredEducation} />
				<Business data={filteredBusiness} />
				<Treding />
				<hr />
				<LifeStyle data={filteredLifeStyle} />
				<PortfolioList />
				<hr />
				<OtherNews />
			</section>
		</Fragment>
	);
};

export default BlogListing;
