export const UpcomingWebinarsData = [
	{
		id: 1,
		image:
			'https://ddnews.gov.in/wp-content/uploads/2024/04/gettyimages-2113290635-612x612-1.jpg',
		title: `Biden Will Visit Baltimore Next Week After Bridge Collaps`,
		date: 'Thu, November 10, 2023',
		time: '6:00 PM – 8:00 PM GMT'
	},
	{
		id: 2,
		image:
			'https://ddnews.gov.in/wp-content/uploads/2024/04/gettyimages-1778705436-612x612-1.jpg',
		title: `OpenAI removes Sam Altman's ownership of its Startup Fund`,
		date: 'June 26, 2023',
		time: '8:00 AM PDT'
	},
	{
		id: 3,
		image:
			'https://ddnews.gov.in/wp-content/uploads/2024/04/gettyimages-1369901491-612x612-1.jpg',
		title: `Arunachal Pradesh was, is and will always be a state of India": S. Jaishankar`,
		date: 'November 10, 2023',
		time: '10:00 PM IST'
	},
	{
		id: 3,
		image:
			'https://ddnews.gov.in/wp-content/uploads/2024/04/ANI-20240401142222.jpg',
		title: `Manjrekar openly asks Wankhede crowd to 'behave' as fans boo Hardik Pandya`,
		date: 'November 10, 2023',
		time: '10:00 PM IST'
	}
];

export default UpcomingWebinarsData;
