// Import node module libraries
import { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Image, Form, ListGroup, Button } from 'react-bootstrap';

// Import MDI icons
import Icon from '@mdi/react';
import { mdiFacebook, mdiTwitter, mdiInstagram } from '@mdi/js';

const FooterWithLinks = () => {
	return (
		<Fragment>
			<footer className="pt-lg-10 pt-5 footer bg-dark text-white">
				<Row className="mx-4">
					<Col lg={3} md={6} sm={12}>
						{/* About Company */}
						<div className="mb-4">
							<Image
								src={`/images/JT-removebg-preview.png`}
								alt="Company Logo"
								className="logo-inverse mb-3 w-50"
							/>

							<div className="mt-4">
								<p className="mb-4">We will send you breaking news daily</p>
								<div className="d-flex mb-3">
									<Form.Control
										type="text"
										placeholder="Enter your email"
										className="me-2 rounded-0"
									/>
									<Button className="btn btn-danger rounded-0">
										SUBSCRIBE
									</Button>
								</div>
							</div>
						</div>
					</Col>

					<Col lg={2} md={3} sm={6}>
						<div className="mb-4 text-white">
							{/* Today's Rate */}
							<h3 className="fw-bold mb-3 text-white">Today's Rate</h3>
							<ListGroup
								as="ul"
								bsPrefix="list-unstyled"
								className="nav nav-footer flex-column nav-x-0"
							>
								<ListGroup.Item as="li">
									<Link to="#" className="nav-link text-white">
										Gold Rate Today
									</Link>
								</ListGroup.Item>
								<ListGroup.Item as="li">
									<Link to="#" className="nav-link text-white">
										Silver Rate Today
									</Link>
								</ListGroup.Item>
								<ListGroup.Item as="li">
									<Link to="#" className="nav-link text-white">
										Petrol Rate Today
									</Link>
								</ListGroup.Item>
								<ListGroup.Item as="li">
									<Link to="#" className="nav-link text-white">
										Diesel Rate Today
									</Link>
								</ListGroup.Item>
								<ListGroup.Item as="li">
									<Link to="#" className="nav-link text-white">
										Trending News
									</Link>
								</ListGroup.Item>
							</ListGroup>
						</div>
					</Col>

					<Col lg={2} md={3} sm={6}>
						<div className="mb-4">
							{/* Today's Highlights */}
							<h3 className="fw-bold mb-3 text-white">Today's Highlights</h3>
							<ListGroup
								as="ul"
								bsPrefix="list-unstyled"
								className="nav nav-footer flex-column nav-x-0"
							>
								<ListGroup.Item as="li">
									<Link
										to="/marketing/blog/international-news"
										className="nav-link text-white"
									>
										International
									</Link>
								</ListGroup.Item>
								<ListGroup.Item as="li">
									<Link
										to="/marketing/blog/allNational"
										className="nav-link text-white"
									>
										National News
									</Link>
								</ListGroup.Item>
								<ListGroup.Item as="li">
									<Link to="#" className="nav-link text-white">
										Entertainment Wellness
									</Link>
								</ListGroup.Item>
								<ListGroup.Item as="li">
									<Link to="#" className="nav-link text-white">
										Bollywood Highlights
									</Link>
								</ListGroup.Item>
								<ListGroup.Item as="li">
									<Link
										to="/marketing/blog/sports-news"
										className="nav-link text-white"
									>
										Sports Highlights
									</Link>
								</ListGroup.Item>
								<ListGroup.Item as="li">
									<Link
										to="/marketing/blog/business"
										className="nav-link text-white"
									>
										Business Tips
									</Link>
								</ListGroup.Item>
								<ListGroup.Item as="li">
									<Link
										to="/marketing/blog/science-and-technology"
										className="nav-link text-white"
									>
										Science & Tech
									</Link>
								</ListGroup.Item>
							</ListGroup>
						</div>
					</Col>

					<Col lg={3} md={3} sm={6}>
						<div className="mb-4">
							{/* Trending News */}
							<h3 className="fw-bold mb-3 text-white">Trending News</h3>
							<ListGroup
								as="ul"
								bsPrefix="list-unstyled"
								className="nav nav-footer flex-column nav-x-0"
							>
								<ListGroup.Item as="li">
									<Link to="#" className="nav-link text-white">
										Latest News
									</Link>
								</ListGroup.Item>
								<ListGroup.Item as="li">
									<Link to="#" className="nav-link text-white">
										Health and Wellness
									</Link>
								</ListGroup.Item>
								<ListGroup.Item as="li">
									<Link to="#" className="nav-link text-white">
										Educational News
									</Link>
								</ListGroup.Item>
								<ListGroup.Item as="li">
									<Link to="#" className="nav-link text-white">
										Mukesh Khanna Says Zeenat Aman ‘Has Lived According To
										Western Civilisation’ As He Criticises Her Stance On Live-In
										Relationships: ‘Think Before Speaking’
									</Link>
								</ListGroup.Item>
								<ListGroup.Item as="li">
									<Link to="#" className="nav-link text-white">
										Business News
									</Link>
								</ListGroup.Item>
							</ListGroup>
						</div>
					</Col>

					<Col lg={2} md={12} sm={12}>
						{/* Contact Info */}
						<div className="mb-4">
							<h3 className="fw-bold mb-3 text-white">Quick Links</h3>

							<p className="mb-1">
								<Link to="#" className="text-white">
									About Us
								</Link>
							</p>
							{/* <p className="mb-1">
								<Link to="#" className="text-white">
									Privacy and Policy
								</Link>
							</p> */}

							<p>
								<Link to="#" className="text-white">
									Contact Us
								</Link>
							</p>
							{/* <div className="d-flex">
								<Link to="#" className=" text-white">
									
								</Link>
							</div> */}
							{/* Social Media */}
							<div className="mb-2">
								<h5 className="fw-bold text-white">Social Media</h5>
							</div>

							<div className="fs-4 d-flex justify-content-start mt-3">
								<Link to="#" className="text-muted me-3">
									<Icon path={mdiFacebook} size={0.8} />
								</Link>
								<Link to="#" className="text-muted me-3">
									<Icon path={mdiTwitter} size={0.8} />
								</Link>
								<Link to="#" className="text-muted me-3">
									<Icon path={mdiInstagram} size={0.8} />
								</Link>
								<Link href="#" target="_blank">
									<Image
										src="https://ddnews.gov.in/wp-content/themes/ddnews/assets/theme-assets/images/youtube.svg"
										alt=""
										className="mx-1 cursor-pointer w-75 h-100"
										style={{ cursor: 'pointer', margin: '0 0.5rem' }}
									/>
								</Link>
							</div>
						</div>
						<p className="mb-1">
							<Link to="#" className="text-white">
								Advertise with Us
							</Link>
						</p>
						<p className="mb-1">
							<Link to="#" className="text-white">
								T&C
							</Link>
						</p>
					</Col>
				</Row>
				<Row className="align-items-center g-0 border-top py-2 mt-6">
					{/* Footer Description */}
					<Col lg={4} md={5} sm={12}>
						<span className="text-white">
							Developed by sksoftwaresolution. All Rights Reserved.
						</span>
					</Col>
					{/* Footer Links */}
					<Col lg={8} md={7} sm={12} className="d-md-flex justify-content-end">
						<nav className="nav nav-footer">
							<Link className="nav-link text-white ps-0" to="#">
								Privacy Policy
							</Link>
							<Link className="nav-link text-white px-2 px-md-3" to="#">
								Cookie Notice
							</Link>
							<Link className="nav-link text-white" to="#">
								Terms of Use
							</Link>
						</nav>
					</Col>
				</Row>
			</footer>
		</Fragment>
	);
};

export default FooterWithLinks;
