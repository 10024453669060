import React, { Fragment, useState, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import {
	Col,
	Row,
	Dropdown,
	Breadcrumb,
	Card,
	Button,
	Modal,
	Form
} from 'react-bootstrap';
import { Trash, Send, Inbox, MoreVertical, Edit } from 'react-feather';
import toast, { Toaster } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import {
	fetchCategories,
	deleteCategory,
	updateCategory
} from 'slices/categorySlice';
import TanstackTable from 'components/elements/advance-table/TanstackTable';
import Checkbox from 'components/elements/advance-table/Checkbox';
import Loader from 'components/Loader/Loader';
import Error from 'components/Error/Error';
import { STATUS } from 'utils/status';
import axios from 'axios';

const Category = () => {
	const dispatch = useDispatch();
	const categories = useSelector((state) => state.category.data.categories);

	useEffect(() => {
		dispatch(fetchCategories());
	}, [dispatch]);

	const [scrollShow, setScrollShow] = useState(false);
	const [isEditMode, setIsEditMode] = useState(false);
	const [selectedCategoryId, setSelectedCategoryId] = useState(null);
	const [formData, setFormData] = useState({ catName: '' });

	const handleClose = () => {
		setScrollShow(false);
		setIsEditMode(false);
		setSelectedCategoryId(null);
		setFormData({ catName: '' });
	};

	const handleShow = () => setScrollShow(true);

	const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
		<Link
			to=""
			ref={ref}
			onClick={(e) => {
				e.preventDefault();
				onClick(e);
			}}
			className="btn-icon btn btn-ghost btn-sm rounded-circle"
		>
			{children}
		</Link>
	));

	const handleChange = (e) => {
		setFormData({ ...formData, [e.target.id]: e.target.value });
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		try {
			if (isEditMode) {
				const response = await dispatch(
					updateCategory({
						categoryId: selectedCategoryId,
						categoryData: formData
					})
				);
				if (response?.payload?.status === 200) {
					toast.success('Category updated successfully');
					dispatch(fetchCategories());
					handleClose();
				} else {
					toast.error('Failed to update category');
				}
			} else {
				const response = await axios.post(
					'https://news-api-fxy6.onrender.com/api/addcategory',
					formData
				);
				if (response.status === 200) {
					toast.success('Category created successfully');
					dispatch(fetchCategories());
					handleClose();
				} else {
					toast.error('Category not created successfully!');
				}
			}
		} catch (error) {
			console.error('Error creating/updating category:', error);
			toast.error('An error occurred while creating/updating the category.');
		}
	};

	const handleDelete = async (categoryId) => {
		try {
			await dispatch(deleteCategory(categoryId));
			toast.success('Category deleted successfully');
		} catch (error) {
			console.error('Error deleting category:', error);
			toast.error('An error occurred while deleting the category.');
		}
	};

	const handleEdit = (category) => {
		setIsEditMode(true);
		setSelectedCategoryId(category?._id);
		setFormData({ catName: category.catName });
		handleShow();
	};

	const ActionMenu = ({ category }) => (
		<Dropdown>
			<Dropdown.Toggle as={CustomToggle}>
				<MoreVertical size="15px" className="text-secondary" />
			</Dropdown.Toggle>
			<Dropdown.Menu align={'end'}>
				<Dropdown.Header>ACTION</Dropdown.Header>
				<Dropdown.Item eventKey="1">
					<Send size="15px" className="dropdown-item-icon" /> Publish
				</Dropdown.Item>
				<Dropdown.Item eventKey="2">
					<Inbox size="15px" className="dropdown-item-icon" /> Move to Draft
				</Dropdown.Item>
				<Dropdown.Item eventKey="3" onClick={() => handleEdit(category)}>
					<Edit size="15px" className="dropdown-item-icon" /> Edit
				</Dropdown.Item>
				<Dropdown.Item eventKey="4" onClick={() => handleDelete(category?._id)}>
					<Trash size="15px" className="dropdown-item-icon" /> Delete
				</Dropdown.Item>
			</Dropdown.Menu>
		</Dropdown>
	);

	const columns = useMemo(
		() => [
			{
				id: 'select',
				header: ({ table }) => (
					<Checkbox
						{...{
							checked: table.getIsAllRowsSelected(),
							indeterminate: table.getIsSomeRowsSelected(),
							onChange: table.getToggleAllRowsSelectedHandler()
						}}
					/>
				),
				cell: ({ row }) => (
					<div className="px-1">
						<Checkbox
							{...{
								checked: row.getIsSelected(),
								disabled: !row.getCanSelect(),
								indeterminate: row.getIsSomeSelected(),
								onChange: row.getToggleSelectedHandler()
							}}
						/>
					</div>
				)
			},
			{
				accessorKey: 'catName',
				header: 'Category',
				cell: ({ getValue }) => (
					<Link href="#" className="text-inherit position-relative">
						<h5 className="mb-0 text-primary-hover">{getValue()}</h5>
					</Link>
				)
			},
			{
				accessorKey: 'action',
				header: 'Action',
				cell: ({ row }) => <ActionMenu category={row.original} />
			}
		],
		[]
	);

	const data = useMemo(() => categories, [categories]);

	return (
		<Fragment>
			<Toaster />
			<Row>
				<Col lg={12} md={12} sm={12}>
					<div className="border-bottom pb-4 mb-4 d-md-flex align-items-center justify-content-between">
						<div className="mb-3 mb-md-0">
							<h1 className="mb1 h2 fw-bold">Category</h1>
							<Breadcrumb>
								<Breadcrumb.Item href="#">Dashboard</Breadcrumb.Item>
								<Breadcrumb.Item href="#">CMS</Breadcrumb.Item>
								<Breadcrumb.Item active>Category</Breadcrumb.Item>
							</Breadcrumb>
						</div>
						<div>
							<Button variant="primary" onClick={handleShow}>
								Add New Post Category
							</Button>
						</div>
					</div>
				</Col>
			</Row>
			<Row>
				<Col lg={12} md={12} sm={12}>
					<Card>
						<Card.Body className="p-0">
							<TanstackTable
								data={data}
								columns={columns}
								filter={true}
								filterPlaceholder="Search Category"
								pagination={true}
							/>
						</Card.Body>
					</Card>
				</Col>
			</Row>
			<Modal show={scrollShow} onHide={handleClose}>
				<Modal.Header closeButton>
					<Modal.Title>
						{isEditMode ? 'Update Category' : 'Add New Category'}
					</Modal.Title>
				</Modal.Header>
				<Form onSubmit={handleSubmit}>
					<Modal.Body>
						<Form.Label>Category Name</Form.Label>
						<Form.Control
							type="text"
							id="catName"
							placeholder="Enter category"
							required
							value={formData.catName}
							onChange={handleChange}
						/>
					</Modal.Body>
					<Modal.Footer>
						<Button variant="secondary" onClick={handleClose}>
							Close
						</Button>
						<Button variant="primary" type="submit">
							Save Changes
						</Button>
					</Modal.Footer>
				</Form>
			</Modal>
		</Fragment>
	);
};

export default Category;
