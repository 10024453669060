import { Fragment, useEffect, useState, useRef } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import {
	Col,
	Row,
	Breadcrumb,
	Card,
	Button,
	Form,
	ListGroup,
	Alert
} from 'react-bootstrap';
import ReactQuillEditor from 'components/elements/editor/ReactQuillEditor';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCategories } from 'slices/categorySlice';
import DotBadge from 'components/elements/bootstrap/DotBadge';
const AddNewPost = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const postId = queryParams.get('postId');
	const dispatch = useDispatch();
	const categories = useSelector((state) => state.category.data.categories);

	const [formData, setFormData] = useState({
		date: '',
		postTitle: '',
		description: '',
		category: '',
		thumbnail: null,
		editorContent: ''
	});
	const [image, setImage] = useState(null);
	const [formError, setFormError] = useState(null);
	const [successMessage, setSuccessMessage] = useState(null);
	useEffect(() => {
		dispatch(fetchCategories());
	}, [dispatch]);

	useEffect(() => {
		if (postId) {
			const fetchBlog = async () => {
				try {
					const response = await axios.get(
						`${process.env.REACT_APP_BACKEND_URL}/api/getOneBlog/${postId}`
					);
					const blog = response?.data?.BlogExist;
					setFormData({
						date: blog.date,
						postTitle: blog.postTitle,
						description: blog.description,
						category: blog.category,
						thumbnail: null,
						editorContent: blog.editorContent
					});
					setImage(blog.thumbnail);
				} catch (error) {
					console.error('Error fetching blog:', error);
					setFormError('Error fetching blog data');
				}
			};
			fetchBlog();
		}
	}, [postId]);

	const categoryOptions = categories?.map((category) => ({
		value: category._id,
		label: category.catName
	}));

	const handleChange = (e) => {
		const { id, value } = e.target;
		setFormData((prevFormData) => ({
			...prevFormData,
			[id]: value
		}));
	};

	const handleImageChange = (e) => {
		const file = e.target.files[0];
		const reader = new FileReader();
		reader.onload = (event) => {
			setImage(event.target.result);
		};
		setFormData((prevFormData) => ({
			...prevFormData,
			thumbnail: file
		}));
		reader.readAsDataURL(file);
	};

	const handleEditorChange = (newContent) => {
		setFormData((prevFormData) => ({
			...prevFormData,
			editorContent: newContent
		}));
	};
	const handleSubmit = async (e) => {
		e.preventDefault();
		if (
			!formData.postTitle ||
			!formData.description ||
			!formData.category ||
			!formData.editorContent
		) {
			setFormError('Please fill in all required fields.');
			return;
		}
		try {
			const productData = new FormData();
			productData.append('date', formData.date);
			productData.append('postTitle', formData.postTitle);
			productData.append('description', formData.description);
			productData.append('category', formData.category);
			productData.append('thumbnail', formData.thumbnail);
			productData.append('editorContent', formData.editorContent);
			let response;
			if (postId) {
				response = await axios.put(
					`${process.env.REACT_APP_BACKEND_URL}/api/updateblog/${postId}`,
					productData,
					{
						headers: { 'Content-Type': 'multipart/form-data' }
					}
				);
			} else {
				response = await axios.post(
					`${process.env.REACT_APP_BACKEND_URL}/api/addblog`,
					productData,
					{
						headers: { 'Content-Type': 'multipart/form-data' }
					}
				);
			}
			if (response.status === 200) {
				setFormData({
					date: '',
					postTitle: '',
					description: '',
					category: '',
					thumbnail: null,
					editorContent: ''
				});
				setImage(null);
				setFormError(null);
				setSuccessMessage(
					`Blog ${postId ? 'updated' : 'created'} successfully`
				);
				navigate('/cms/all-posts');
			} else {
				setFormError(`Failed to ${postId ? 'update' : 'create'} blog`);
			}
		} catch (error) {
			console.error(`Error ${postId ? 'updating' : 'creating'} blog:`, error);
			setFormError(`Error ${postId ? 'updating' : 'creating'} blog`);
		}
	};
	return (
		<Fragment>
			<Row>
				<Col lg={12}>
					<div className="border-bottom pb-4 mb-4 d-md-flex align-items-center justify-content-between">
						<div className="mb-3 mb-md-0">
							<h1 className="mb-1 h2 fw-bold">
								{postId ? 'Edit Post' : 'Add New Post'}
							</h1>
							<Breadcrumb>
								<Breadcrumb.Item href="#">Dashboard</Breadcrumb.Item>
								<Breadcrumb.Item href="#">CMS</Breadcrumb.Item>
								<Breadcrumb.Item active>
									{postId ? 'Edit Post' : 'Add New Post'}
								</Breadcrumb.Item>
							</Breadcrumb>
						</div>
						<div>
							<Link to="/cms/all-posts" className="btn btn-outline-secondary">
								Back to All Posts
							</Link>
						</div>
					</div>
				</Col>
			</Row>

			<Row>
				<Col xl={9} lg={8} md={12}>
					<Card>
						<Card.Header>
							<h4 className="mb-0">{postId ? 'Edit Post' : 'Create Post'}</h4>
						</Card.Header>
						<Card.Body>
							<Form className="mt-4" onSubmit={handleSubmit}>
								<Row>
									<Col md={9}>
										<Form.Group className="mb-3" controlId="postTitle">
											<Form.Label>Title</Form.Label>
											<Form.Control
												type="text"
												placeholder="Post Title"
												value={formData.postTitle}
												onChange={handleChange}
												isInvalid={!formData.postTitle}
											/>
											<Form.Control.Feedback type="invalid">
												Please provide a title.
											</Form.Control.Feedback>
										</Form.Group>
										<Form.Group className="mb-3">
											<Form.Label>Date</Form.Label>
											<Form.Control
												type="date"
												id="date"
												value={formData.date}
												onChange={handleChange}
											/>
										</Form.Group>
										<Form.Group className="mb-3">
											<Form.Label>Thumbnail</Form.Label>
											<Form.Control
												type="file"
												id="thumbnail"
												accept="image/*"
												onChange={handleImageChange}
											/>
											{image && (
												<img
													src={image}
													alt="Thumbnail Preview"
													style={{ maxWidth: '100%', marginTop: '10px' }}
												/>
											)}
										</Form.Group>

										<Form.Group className="mb-3">
											<Form.Label>Description</Form.Label>
											<Form.Control
												as="textarea"
												placeholder="Description"
												id="description"
												value={formData.description}
												onChange={handleChange}
												isInvalid={!formData.description}
											/>
											<Form.Control.Feedback type="invalid">
												Please provide a description.
											</Form.Control.Feedback>
										</Form.Group>

										<Form.Group className="mb-3">
											<Form.Label>Category</Form.Label>
											<Form.Control
												as="select"
												id="category"
												value={formData.category}
												onChange={handleChange}
												isInvalid={!formData.category}
											>
												<option value="">Select Category</option>
												{categoryOptions?.map((option) => (
													<option key={option.value} value={option.value}>
														{option.label}
													</option>
												))}
											</Form.Control>
											<Form.Control.Feedback type="invalid">
												Please select a category.
											</Form.Control.Feedback>
											<Form.Text className="text-muted">
												Select the category to which the post belongs.
											</Form.Text>
										</Form.Group>

										<Form.Group className="mb-3">
											<Form.Label>Content</Form.Label>
											<ReactQuillEditor
												value={formData.editorContent}
												onChange={handleEditorChange}
											/>
										</Form.Group>

										{formError && <Alert variant="danger">{formError}</Alert>}
										{successMessage && (
											<Alert variant="success">{successMessage}</Alert>
										)}

										<Button type="submit" variant="primary">
											{postId ? 'Update Post' : 'Create Post'}
										</Button>
									</Col>
								</Row>
							</Form>
						</Card.Body>
					</Card>
				</Col>
				<Col xl={3} lg={4} md={12}>
					<div className="mb-4 mb-lg-0">
						<div className="mb-3">
							<Card>
								<Card.Header>
									<h4 className="mb-0">Categories</h4>
								</Card.Header>
								<Card.Body>
									<ListGroup>
										{categoryOptions?.map((category) => (
											<ListGroup.Item
												key={category.value}
												className="d-flex align-items-center justify-content-between"
											>
												<div className="d-flex align-items-center">
													<DotBadge bg="info" />
													<h5 className="mb-0 ms-3">{category.label}</h5>
												</div>
											</ListGroup.Item>
										))}
									</ListGroup>
								</Card.Body>
							</Card>
						</div>
					</div>
					<Card className="mb-3">
						<Card.Header>
							<h4 className="mb-0">Status</h4>
						</Card.Header>
						<Card.Body>
							<ListGroup>
								<ListGroup.Item>
									<div className="d-flex align-items-center justify-content-between">
										<span>Visibility:</span>
										<span className="fw-bold">Public</span>
									</div>
								</ListGroup.Item>
								<ListGroup.Item>
									<div className="d-flex align-items-center justify-content-between">
										<span>Status:</span>
										<DotBadge bg="success">Published</DotBadge>
									</div>
								</ListGroup.Item>
								<ListGroup.Item>
									<div className="d-flex align-items-center justify-content-between">
										<span>Author:</span>
										<span className="fw-bold">Amit Jain</span>
									</div>
								</ListGroup.Item>
							</ListGroup>
						</Card.Body>
					</Card>

					<Card>
						<Card.Header>
							<h4 className="mb-0">Publish</h4>
						</Card.Header>
						<Card.Body>
							<div className="d-grid mb-2">
								<Button variant="outline-primary">Save Draft</Button>
							</div>
							<div className="d-grid">
								<Button variant="primary">Publish</Button>
							</div>
						</Card.Body>
					</Card>
				</Col>
			</Row>
		</Fragment>
	);
};

export default AddNewPost;
