// import node module libraries
import { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Col, Row, Card, Form, Button, Image } from 'react-bootstrap';

// import media files
import Logo from 'assets/images/brand/logo/logo-icon.svg';

const ForgetPassword = () => {
	const bgStyle = {
		backgroundImage:
			'url(https://www.indiafilings.com/learn/wp-content/uploads/2023/03/Where-can-I-register-a-firm-in-India.jpg)',
		backgroundSize: 'cover',
		backgroundPosition: 'center center',
		// width: '100vw',
		height: '100vh',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		paddingRight: '2rem'
		// margin:"auto"
	};

	const cardStyle = {
		backgroundColor: 'rgba(255, 255, 255, 0.75)',
		padding: '2rem',
		borderRadius: '10px',
		boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)'
	};
	return (
		<Fragment>
			<Row
				className="align-items-center justify-content-center g-0 min-vh-100"
				style={bgStyle}
			>
				<Col lg={5} md={5} className="py-8 py-xl-0">
					<Card style={cardStyle}>
						<Card.Body className="p-6">
							<div className="mb-4">
								<Link to="/">
									<Image
										src={`/images/JT-removebg-preview.png`}
										className="mb-4 w-50 h-15"
										alt=""
									/>
								</Link>
								<h1 className="mb-1 fw-bold">Forgot Password</h1>
								<span>Fill the form to reset your password.</span>
							</div>
							{/* Form */}
							<Form>
								<Row>
									<Col lg={12} md={12} className="mb-3">
										{/*  email */}
										<Form.Label>Email</Form.Label>
										<Form.Control
											type="email"
											id="email"
											placeholder="Enter your email"
											required
										/>
									</Col>
									<Col lg={12} md={12} className="mb-3 d-grid gap-2">
										{/* Button */}
										<Button variant="primary" type="submit">
											Send Reset Link
										</Button>
									</Col>
								</Row>
								<span>
									Return to <Link to="/authentication/sign-in">Sign in</Link>
								</span>
							</Form>
						</Card.Body>
					</Card>
				</Col>
			</Row>
		</Fragment>
	);
};

export default ForgetPassword;
