// import node module libraries
import React, { Fragment, useEffect } from 'react';
import { Col, Row, Container, Image, Form, Button } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
// import sub components
import BlogCard from './BlogCard';
import { fetchBlogById, fetchBlogs } from 'slices/BlogSlice';
import { fetchCategories } from 'slices/categorySlice';
const BlogArticleSingle = () => {
	const { id } = useParams();
	const dispatch = useDispatch();
	const {
		data: { blogs },
		status,
		error
	} = useSelector((state) => state?.blog);
	const blogData = blogs.find((blog) => blog._id === id);
	const arrayBufferToBase64 = (buffer) => {
		let binary = '';
		const bytes = new Uint8Array(buffer);
		const len = bytes.byteLength;
		for (let i = 0; i < len; i++) {
			binary += String.fromCharCode(bytes[i]);
		}
		return window.btoa(binary);
	};
	const image = blogData?.thumbnail;
	const base64Image = arrayBufferToBase64(image?.data?.data);
	const categories = useSelector((state) => state?.category?.data?.categories);
	const categoryIdToNameMap = categories?.reduce((map, category) => {
		map[category._id] = category.catName;
		return map;
	}, {});
	const categoryName = categoryIdToNameMap[blogs.category];
	useEffect(() => {
		if (id) {
			dispatch(fetchBlogById(id));
			// dispatch(fetchBlogs());
			dispatch(fetchCategories());
		}
	}, [dispatch, id]);

	const authorAndSharing = (data) => {
		return (
			<div className="d-flex justify-content-between align-items-center">
				{/* <div className="d-flex align-items-center">
					<Image
						src={data.authorimage}
						alt=""
						className="rounded-circle avatar-md"
					/>
					<div className="ms-2 lh-1">
						<h5 className="mb-1 ">{data.authorname}</h5>
						<span className="text-primary">{data.aboutauthor}</span>
					</div>
				</div> */}
				{/* <div>
					<span className="ms-2 text-muted">Share</span>
					<Link to="#" className="ms-2 text-muted">
						<i className="fab fa-facebook"></i>
					</Link>
					<Link to="#" className="ms-2 text-muted">
						<i className="fab fa-twitter"></i>
					</Link>
					<Link to="#" className="ms-2 text-muted ">
						<i className="fab fa-linkedin"></i>
					</Link>
				</div> */}
			</div>
		);
	};

	if (status === 'loading') {
		return <p>Loading...</p>;
	}

	if (status === 'failed') {
		return <p>Error: {error}</p>;
	}

	return (
		<Fragment>
			{blogData && (
				<section className="py-4 py-lg-8 pb-14 bg-white">
					<Container>
						<Row className="justify-content-start w-75">
							<Col xl={12} lg={12} md={12} sm={12} className="mb-2">
								<div className="text-start mb-4">
									<Link
										to="#"
										className="fs-5 fw-semi-bold d-block mb-4 text-primary"
									>
										{categoryName}
									</Link>
									<h1 className="display-4  fw-bold mb-4">
										{blogData.postTitle}
									</h1>
									<span className=" d-inline-block fs-3">
										{blogData.description}
									</span>
								</div>
								{/* {authorAndSharing(blogData)} */}
							</Col>
						</Row>
						<Row className="justify-content-center align-items-center w-75 h-75">
							<Col xl={12} lg={12} md={12} sm={12} className="mb-4">
								<Image
									src={`data:${blogData?.contentType};base64,${base64Image}`}
									alt=""
									className="w-100 h-75 rounded-3"
								/>
							</Col>
						</Row>
						<Row className="justify-content-start w-75">
							<Col xl={12} lg={12} md={12} sm={12} className="mb-2">
								{/* Blog Content */}
								<div
									className="fs-3 justify-content-center align-items-center"
									dangerouslySetInnerHTML={{
										__html: blogData.editorContent // Assuming description holds the blog content
									}}
								></div>
								<hr className="mt-8 mb-5" />
								{/* Author */}
								{authorAndSharing(blogData)}
								{/* Subscribe to Newsletter */}
								<div className="py-12">
									<div className="text-center mb-6">
										<h2 className="display-4 fw-bold">
											Sign up for our Newsletter
										</h2>
										<p className="mb-0 lead">
											Join our newsletter and get resources, curated content,
											and design inspiration delivered straight to your inbox.
										</p>
									</div>
									{/* Form */}
									<Form className="row px-md-20">
										<Form.Group
											className="mb-3 col ps-0 ms-2 ms-md-0"
											controlId="formBasicEmail"
										>
											<Form.Control type="email" placeholder="Email Address" />
										</Form.Group>
										<Form.Group
											className="mb-3 col-auto ps-0"
											controlId="formSubmitButton"
										>
											<Button variant="primary" type="submit">
												Submit
											</Button>
										</Form.Group>
									</Form>
								</div>
							</Col>
						</Row>
					</Container>

					{/* Related Post */}
					<Container>
						<Row>
							<Col xl={12} lg={12} md={12} sm={12}>
								<div className="my-5">
									<h2>Related Post</h2>
								</div>
							</Col>
							{/* Assuming that blog.relatedPosts is an array of related posts */}
							{/* {blog.relatedPosts?.map((relatedPost, index) => (
								<Col xl={4} lg={4} md={6} sm={12} key={index} className="d-flex">
									<BlogCard item={relatedPost} />
								</Col>
							))} */}
						</Row>
					</Container>
				</section>
			)}
		</Fragment>
	);
};

export default BlogArticleSingle;
