// import node module libraries
import { Col, Row, Card, Image } from 'react-bootstrap';
import { CalendarCheck, Clock } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';
const BuildingSkills = (props) => {
	const TopStoriesData = props?.data;
	const arrayBufferToBase64 = (buffer) => {
		let binary = '';
		const bytes = new Uint8Array(buffer);
		const len = bytes.byteLength;
		for (let i = 0; i < len; i++) {
			binary += String.fromCharCode(bytes[i]);
		}
		return window.btoa(binary);
	};

	return (
		<section className="pb-lg-14 pb-6 mx-4 ">
			<Row>
				<Col xl={12} md={12} xs={12}>
					<div className=" mb-lg-4 mb-2 mt-3 ">
						<h2 className="display-3 fw-bold">
							TOP{' '}
							<u className="text-success">
								<span className="text-danger">STORIES</span>
							</u>
						</h2>
					</div>
				</Col>
			</Row>
			<Row className="align-items-center ">
				{TopStoriesData?.slice(0, 1).map((item, index) => {
					const image = item?.thumbnail;
					const base64Image = arrayBufferToBase64(image?.data?.data);
					return (
						<Col xl={5} lg={6} md={12} xs={12} key={index}>
							<div
								className="mb-6 mb-lg-0 mt-4 shadow-lg"
								style={{ height: '700px' }}
							>
								<div className="mb-2">
									<Image
										src={`data:${item.contentType};base64,${base64Image}`}
										alt="certificate"
										className="img-fluid w-100"
									/>
								</div>
								<div>
									<h2>{item.postTitle}</h2>
									<p className="ms-2 fs-3">{item.description}</p>
								</div>
							</div>
						</Col>
					);
				})}
				<Col xl={7} lg={6} md={12} xs={12}>
					<Row className="row-cols-lg-2 row-cols-md-2 row-cols-1 ">
						{TopStoriesData?.slice(1, 5).map((item, index) => {
							const image = item?.thumbnail;
							const base64Image = arrayBufferToBase64(image?.data?.data);
							return (
								<Col key={index}>
									<Card className="mb-xl-0 card-hover  my-4 rounded-0 ">
										<Link to={`/marketing/blog/article-single/${item._id}`}>
											<div
												style={{
													width: '100%',
													height: '250px',
													overflow: 'hidden'
												}}
											>
												<Image
													src={`data:${item.contentType};base64,${base64Image}`}
													alt="webinar-1"
													className="w-100 h-100 img-fluid"
													style={{ objectFit: 'cover' }}
												/>
											</div>
										</Link>
										<Card.Body>
											<div className="my-1 lh-1">
												<span className="me-1">
													<CalendarCheck size={14} />
												</span>
												<span>{item.date}</span>
											</div>
											<h3 className="mb-2 text-truncate">
												<Link
													to={`/marketing/blog/article-single/${item._id}`}
													className="text-inherit"
												>
													{item.postTitle}
												</Link>
											</h3>
										</Card.Body>
									</Card>
								</Col>
							);
						})}
					</Row>
				</Col>
			</Row>
		</section>
	);
};

export default BuildingSkills;
