// import necessary libraries and components
import { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Image, Navbar, Nav, Container } from 'react-bootstrap';
import { useMediaQuery } from 'react-responsive';

// import sub layout components
import NavDropdownMain from 'layouts/marketing/navbars/NavDropdownMain';
import QuickMenu from 'layouts/QuickMenu';

// import data files
import NavbarDefaultRoutes from 'routes/marketing/NavbarDefault';

const NavbarDefault = ({ headerstyle, login }) => {
	const isDesktop = useMediaQuery({
		query: '(min-width: 1224px)'
	});
	const isLaptop = useMediaQuery({
		query: '(min-width: 1024px)'
	});

	const [expandedMenu, setExpandedMenu] = useState(false);
	const isTokenExists = () => {
		return localStorage.getItem('token') !== null;
	};
	const options = {
		weekday: 'long',
		year: 'numeric',
		month: 'long',
		day: '2-digit'
	};
	const currentDate = new Date().toLocaleDateString('en-US', options);

	return (
		<Fragment>
			<div
				className="bg-danger d-lg-flex py-1 justify-content-lg-between"
				style={{
					justifyContent: 'space-between',
					padding: '0.5rem 1rem'
				}}
			>
				<div
					className="d-lg-flex gap-3 text-center justify-content-center align-items-center"
					style={{ display: 'flex', gap: '1rem', textAlign: 'center' }}
				>
					<div className="text-white text-center cursor-pointer d-flex">
						<div>
							<Image
								src="/images/png/phone-call.png"
								alt=""
								className="apply-now-border"
							/>
						</div>
						<div>Feedback</div>
						<div>
							<span style={{ marginLeft: '10px' }}>{currentDate}</span>{' '}
							{/* Display current date */}
						</div>
					</div>
				</div>
				<div className="d-flex justify-content-center align-items-center">
					<div>
						<Link href="#" target="_blank">
							<Image
								src="https://ddnews.gov.in/wp-content/themes/ddnews/assets/theme-assets/images/twitter-icon.svg"
								alt=""
								className="mx-1 cursor-pointer"
								style={{ cursor: 'pointer', margin: '0 0.5rem' }}
							/>
						</Link>
					</div>
					<div>
						<Link target="_blank" href="#">
							<Image
								src="https://ddnews.gov.in/wp-content/themes/ddnews/assets/theme-assets/images/insta-icon.svg"
								alt=""
								className="mx-1 cursor-pointer"
								style={{ cursor: 'pointer', margin: '0 0.5rem' }}
							/>
						</Link>
					</div>
					<div>
						<Link href="#" target="_blank">
							<Image
								src="https://ddnews.gov.in/wp-content/themes/ddnews/assets/theme-assets/images/youtube.svg"
								alt=""
								className="mx-1 cursor-pointer"
								style={{ cursor: 'pointer', margin: '0 0.5rem' }}
							/>
						</Link>
					</div>
					<div>
						<Link href="#" target="_blank">
							<Image
								src="https://ddnews.gov.in/wp-content/themes/ddnews/assets/theme-assets/images/fb-icon.svg"
								alt=""
								className="mx-1 cursor-pointer"
								style={{ cursor: 'pointer', margin: '0 0.5rem' }}
							/>
						</Link>
					</div>
				</div>
			</div>
			{/* <div
				className="bg-danger d-lg-flex py-1 justify-content-lg-between"
				style={{
					justifyContent: 'space-between',
					padding: '0.5rem 1rem',
				}}
			>
				<div
					className="d-lg-flex gap-3 text-center justify-content-center align-items-center"
					style={{ display: 'flex', gap: '1rem', textAlign: 'center' }}
				>
					<div className="text-white text-center cursor-pointer d-flex">
						<div>
							<Image
								src="/images/png/phone-call.png"
								alt=""
								className="apply-now-border"
							/>
						</div>
						<div>
							Feedback 
						</div>
						<div><span style={{ marginLeft: '10px' }}>{currentDate}</span> </div>
					</div>
				</div>
				<div className="d-flex justify-content-center align-items-center">
					<div>
						<Link target="_blank" href="#">
							<Image
								src="https://ddnews.gov.in/wp-content/themes/ddnews/assets/theme-assets/images/insta-icon.svg"
								alt=""
								className="mx-1 cursor-pointer"
								style={{ cursor: 'pointer', margin: '0 0.5rem' }}
							/>
						</Link>
					</div>
					<div>
						<Link href="#" target="_blank">
							<Image
								src="https://ddnews.gov.in/wp-content/themes/ddnews/assets/theme-assets/images/youtube.svg"
								alt=""
								className="mx-1 cursor-pointer"
								style={{ cursor: 'pointer', margin: '0 0.5rem' }}
							/>
						</Link>
					</div>
					<div>
						<Link href="#" target="_blank">
							<Image
								src="https://ddnews.gov.in/wp-content/themes/ddnews/assets/theme-assets/images/fb-icon.svg"
								alt=""
								className="mx-1 cursor-pointer"
								style={{ cursor: 'pointer', margin: '0 0.5rem' }}
							/>
						</Link>
					</div>
					<div>
						<Link href="#" target="_blank">
							<Image
								src="https://ddnews.gov.in/wp-content/themes/ddnews/assets/theme-assets/images/twitter-icon.svg"
								alt=""
								className="mx-1 cursor-pointer"
								style={{ cursor: 'pointer', margin: '0 0.5rem' }}
							/>
						</Link>
					</div>
				</div>
			</div> */}
			<Navbar
				onToggle={(collapsed) => setExpandedMenu(collapsed)}
				expanded={expandedMenu}
				expand="lg"
				className="navbar p-2 navbar-light py-2 bg-dark "
			>
				<Container fluid className="px-0 ps-2 mx-4">
					<Navbar.Brand as={Link} to="/" className="w-15">
						<Image
							src={`/images/JT-removebg-preview.png`}
							alt=""
							className="w-50 h-25"
						/>
					</Navbar.Brand>
					<div
						className={`navbar-nav navbar-right-wrap ms-auto d-lg-none nav-top-wrap ${
							login ? (isDesktop || isLaptop ? 'd-none' : 'd-flex') : 'd-none'
						}`}
					>
						<QuickMenu />
					</div>
					<Navbar.Toggle aria-controls="basic-navbar-nav">
						<span className="icon-bar top-bar mt-0"></span>
						<span className="icon-bar middle-bar"></span>
						<span className="icon-bar bottom-bar"></span>
					</Navbar.Toggle>
					<Navbar.Collapse id="basic-navbar-nav">
						<Nav>
							{NavbarDefaultRoutes.map((item, index) => {
								if (item.children === undefined) {
									return (
										<div className="nav-item pt-1 ms-1  " key={index}>
											<Nav.Link
												as={Link}
												to={item.link}
												className="text-uppercase  "
											>
												{item.menuitem}
											</Nav.Link>
										</div>
									);
								} else {
									return (
										<NavDropdownMain
											item={item}
											key={index}
											onClick={(value) => setExpandedMenu(value)}
											className=" pt-1 ms-1"
										/>
									);
								}
							})}
						</Nav>
						{/* <Nav className="navbar-nav navbar-right-wrap ms-auto d-flex nav-top-wrap">
							{!isTokenExists() && (
								<Fragment>
									<span
										className={`ms-auto mt-1 ${isTokenExists() ? 'd-none' : ''}`}
									>
										<Nav.Link
											as={Link}
											to="authentication/sign-in/"
											bsPrefix="btn"
											className="btn shadow-sm me-2"
											style={{ backgroundColor: '#de8ed1' }}
										>
											Sign In
										</Nav.Link>
										<Nav.Link
											as={Link}
											to="authentication/sign-up/"
											bsPrefix="btn"
											className="btn shadow-sm"
											style={{ backgroundColor: '#de8ed1' }}
										>
											Sign Up
										</Nav.Link>
									</span>
								</Fragment>
							)}
							{isTokenExists() && (
								<span
									className={`${isDesktop || isLaptop ? 'd-flex' : 'd-none'}`}
								>
									<QuickMenu />
								</span>
							)}
						</Nav> */}
					</Navbar.Collapse>
				</Container>
			</Navbar>
		</Fragment>
	);
};

NavbarDefault.defaultProps = {
	headerstyle: 'navbar-default',
	login: false
};
NavbarDefault.propTypes = {
	headerstyle: PropTypes.string,
	login: PropTypes.bool
};

export default NavbarDefault;
