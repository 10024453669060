// import media files
import EducationImg1 from 'assets/images/education/edu-img-1.jpg';
import EducationImg2 from 'assets/images/education/edu-img-2.jpg';
import EducationImg3 from 'assets/images/education/edu-img-3.jpg';
import EducationImg4 from 'assets/images/education/edu-img-4.jpg';
import EducationImg5 from 'assets/images/education/edu-img-5.jpg';
import EducationImg6 from 'assets/images/education/edu-img-6.jpg';
import EducationImg7 from 'assets/images/education/edu-img-7.jpg';
import EducationImg8 from 'assets/images/education/edu-img-8.jpg';

export const SkillCoursesData = [
	{
		id: 1,
		category: 'Writing',
		image: EducationImg1,
		duration: 8,
		durationUnit: 'Hours',
		title:
			' फोन के साथ झुंड में बैठकर पेपर सॉल्व करते अभ्यर्थी, झारखंड में सिविल सेवा का पेपर लीक हो गया! ',
		rating: 4.9,
		votes: 31
	},
	{
		id: 2,
		category: 'Design',
		image: EducationImg2,
		duration: 12,
		durationUnit: 'Hours',
		title:
			'यूपी में एक और पेपर लीक, 12वीं के मैथ्स और बायोलॉजी के पेपर की तस्वीरें वायरल',
		rating: 4.9,
		votes: 23
	},
	{
		id: 3,
		category: 'Development',
		image: EducationImg3,
		duration: 2,
		durationUnit: 'Hours',
		title: `बोर्ड परीक्षा में खुलेआम नकल, पैसे लेकर खुद स्टाफ करता है मदद, वीडियो भी सामने आया`,
		rating: 5.0,
		votes: 6380
	},
	{
		id: 4,
		category: 'Photography',
		image: EducationImg4,
		duration: 12,
		durationUnit: 'Hours',
		title: `
		BPSC ने शिक्षक भर्ती परीक्षा TRE 3 रद्द की, पेपर लीक के बाद लिया फैसला, अब कब होगा एग्जाम?`,
		rating: 5.0,
		votes: 221
	},
	{
		id: 5,
		category: 'Financial',
		image: EducationImg5,
		duration: 9,
		durationUnit: 'Hours',
		title: `
		UP Police कॉन्स्टेबल भर्ती का पेपर लीक? भर्ती बोर्ड ने बयान तो जारी किया, मगर..`,
		rating: 4.9,
		votes: 76
	},
	{
		id: 6,
		category: 'Marketing ',
		image: EducationImg6,
		duration: 2,
		durationUnit: 'Hours',
		title:
			'CBSE ने दिल्ली समेत देश के कई स्कूलों की मान्यता रद्द की, नाम जानने के लिए क्लिक करें   ',
		rating: 4.9,
		votes: 43
	},
	{
		id: 7,
		category: 'Language',
		image: EducationImg7,
		duration: 2,
		durationUnit: 'Weeks',
		title: `सिपाही भर्ती के नाम पर बेच रहे थे लेखपाल का पुराना पेपर, UP पुलिस ने धर लिया!`,
		rating: 5.0,
		votes: 34
	},
	{
		id: 8,
		category: 'Health',
		image: EducationImg8,
		duration: 3,
		durationUnit: 'Weeks',
		title: `अब CSIR SO/ASO परीक्षा में धांधली के आरोप लगे, क्या है पूरा मामला?`,
		rating: 4.7,
		votes: 75
	}
];

export default SkillCoursesData;
