import { Fragment, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Col, Row, Card, Form, Button, Image } from 'react-bootstrap';
import axios from 'axios';
import toast, { Toaster } from 'react-hot-toast';

const SignIn = () => {
	const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
	const [formData, setFormData] = useState({
		email: '',
		password: ''
	});
	const navigate = useNavigate();
	const handleChange = (e) => {
		setFormData({ ...formData, [e.target.id]: e.target.value });
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		try {
			const response = await axios.post(
				`${process.env.REACT_APP_BACKEND_URL}/api/login`,
				formData
			);
			if (response.status === 200) {
				const { token, role } = response.data;
				const user = response.data;

				// Save the token in local storage
				localStorage.setItem('token', token);

				// Save the user data in session storage
				sessionStorage.setItem('user', JSON.stringify(user));

				toast.success('Login Successful');

				if (role === 'admin') {
					navigate('/dashboard/overview');
				} else {
					navigate('/');
				}
			}
		} catch (error) {
			toast.error('Login failed: ' + error.message);
		}
	};

	return (
		<Fragment>
			<Toaster />
			<Row className="align-items-center justify-content-center g-0 min-vh-100">
				<Col lg={7} md={5} className="d-none d-md-block">
					<Image
						src={`https://www.mswipe.com/assets/images/signin_marchant_mswipe_Illustrations.svg`}
						alt=""
						className="img-fluid"
					/>
				</Col>
				<Col lg={5} md={7} className="py-8 py-xl-0">
					<Card>
						<Card.Body className="p-4">
							<div className="mb-4 text-center">
								<Link to="/">
									<Image
										src={`/images/JT-removebg-preview.png`}
										className="mb-4 w-50 h-15"
										alt=""
									/>
								</Link>
								<h1 className="mb-1 fw-bold">Sign in</h1>
								<span>
									Don’t have an account?{' '}
									<Link to="/authentication/sign-up" className="ms-1">
										Register
									</Link>
								</span>
							</div>
							{/* Form */}
							<Form onSubmit={handleSubmit}>
								<Row>
									<Col lg={12} md={12} className="mb-3">
										{/* Username or email */}
										<Form.Label>Email</Form.Label>
										<Form.Control
											type="email"
											id="email"
											placeholder="Email address"
											value={formData.email}
											onChange={handleChange}
											required
										/>
									</Col>
									<Col lg={12} md={12} className="mb-3">
										{/* Password */}
										<Form.Label>Password</Form.Label>
										<Form.Control
											type="password"
											id="password"
											placeholder="Enter Password"
											value={formData.password}
											onChange={handleChange}
											required
										/>
									</Col>
									<Col lg={12} md={12} className="mb-3">
										{/* Checkbox */}
										<div className="d-md-flex justify-content-between align-items-center">
											<Form.Group
												className="mb-3 mb-md-0"
												controlId="formBasicCheckbox"
											>
												<Form.Check type="checkbox" label="Remember me" />
											</Form.Group>
											<Link to="/authentication/forget-password">
												Forgot your password?
											</Link>
										</div>
									</Col>
									<Col lg={12} md={12} className="mb-0 d-grid gap-2">
										{/* Button */}
										<Button variant="info" className="rounded-0" type="submit">
											Login
										</Button>
									</Col>
								</Row>
							</Form>
						</Card.Body>
					</Card>
				</Col>
			</Row>
		</Fragment>
	);
};

export default SignIn;
