// import node module libraries
import { Col, Row, Card, Image } from 'react-bootstrap';
import { CalendarCheck, Clock } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';
const NationalNews = (props) => {
	const NationalData = props?.data;
	const arrayBufferToBase64 = (buffer) => {
		let binary = '';
		const bytes = new Uint8Array(buffer);
		const len = bytes.byteLength;
		for (let i = 0; i < len; i++) {
			binary += String.fromCharCode(bytes[i]);
		}
		return window.btoa(binary);
	};
	return (
		<section className="pb-lg-4 pb-2 mx-4 ">
			<Row>
				<Col xl={12} md={12} xs={12}>
					<div className=" mb-lg-4 mb-2 mt-3 justify-content-between d-flex ">
						<div>
							<h2 className="display-4 fw-bold">
								INTERNATIONAL ||{' '}
								<u className="text-success">
									<span className="text-danger">NATIONAL</span>
								</u>
							</h2>
						</div>

						<div>
							<Link to="/marketing/blog/allNational">
								<span className="fs-3">
									{' '}
									View All
									<span className="">
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="20"
											height="20"
											fill="currentColor"
											className="bi bi-arrow-right-short"
											viewBox="0 0 16 16"
										>
											<path
												fillRule="evenodd"
												d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"
											/>
										</svg>
									</span>
								</span>
							</Link>
						</div>
					</div>
				</Col>
			</Row>
			<Row className="align-items-center ">
				<Col xl={3} lg={3} md={12} xs={12}>
					<div className="mb-6 mb-lg-0 flex flex-wrap ">
						<div className="mb-2">
							<Image
								src="https://ddnews.gov.in/wp-content/themes/ddnews/assets/theme-assets/images/add-1.jpg"
								alt="certificate"
								className="img-fluid w-100"
							/>
						</div>
						<div>
							<Image
								src="https://ddnews.gov.in/wp-content/themes/ddnews/assets/theme-assets/images/add-2.jpg"
								alt="certificate"
								className="img-fluid w-100"
							/>
						</div>
					</div>
				</Col>
				<Col xl={9} lg={9} md={12} xs={12}>
					<Row className="row-cols-lg-3 row-cols-md-2 row-cols-1 mt-4">
						{NationalData?.slice(0, 6).map((item, index) => {
							const image = item?.thumbnail;
							const base64Image = arrayBufferToBase64(image?.data?.data);
							return (
								<Col key={index}>
									<Card className="mb-xl-0 card-hover my-4 rounded-0 ">
										<Link to={`/marketing/blog/article-single/${item._id}`}>
											<div
												style={{
													width: '100%',
													height: '240px',
													overflow: 'hidden'
												}}
											>
												<Image
													src={`data:${item.contentType};base64,${base64Image}`}
													alt="webinar-1"
													className="w-100 h-100 img-fluid"
													style={{ objectFit: 'cover' }}
												/>
											</div>
										</Link>
										<Card.Body>
											<div className="my-1 lh-1">
												<span className="me-1">
													<CalendarCheck size={14} />
												</span>
												<span>{item.date}</span>
											</div>
											<h3 className="mb-2">
												<Link
													to={`/marketing/blog/article-single/${item._id}`}
													className="text-inherit"
												>
													{item.postTitle}
												</Link>
											</h3>
										</Card.Body>
									</Card>
								</Col>
							);
						})}
					</Row>
				</Col>
			</Row>
		</section>
	);
};

export default NationalNews;
