// import node module libraries
import { Card, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const SkillCourseCard = (props) => {
	const { item } = props;
	const arrayBufferToBase64 = (buffer) => {
		let binary = '';
		const bytes = new Uint8Array(buffer);
		const len = bytes.byteLength;
		for (let i = 0; i < len; i++) {
			binary += String.fromCharCode(bytes[i]);
		}
		return window.btoa(binary);
	};
	const image = item?.thumbnail;
	const base64Image = arrayBufferToBase64(image?.data?.data);

	return (
		<Card className="mb-4 border card-hover" style={{ height: '450px' }}>
			<Link to={`/marketing/blog/article-single/${item._id}`}>
				<Image
					src={`data:${item.contentType};base64,${base64Image}`}
					alt="writing"
					className="img-fluid w-100 rounded-top-3"
				/>
			</Link>
			<Card.Body>
				<div className="d-flex align-items-center mb-2 lh-1">
					<div>
						<span className="ms-1 fw-semibold">Date : {item.date}</span>
					</div>
				</div>
				<h4 className="mb-3">
					<Link
						to={`/marketing/blog/article-single/${item._id}`}
						className="text-inherit"
					>
						{item?.postTitle?.split(' ').slice(0, 15).join(' ')}
					</Link>
				</h4>

				<div>
					<span className="text-inherit  me-1">
						{item?.description?.split(' ').slice(0, 10).join(' ')}
					</span>
				</div>
			</Card.Body>
		</Card>
	);
};

export default SkillCourseCard;
