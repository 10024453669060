// import node module libraries
import { Col, Row, Card, Image } from 'react-bootstrap';
import { CalendarCheck, Clock } from 'react-bootstrap-icons';

import { Link } from 'react-router-dom';
const LifeStyle = (props) => {
	const LifeStyleData = props?.data;
	const arrayBufferToBase64 = (buffer) => {
		let binary = '';
		const bytes = new Uint8Array(buffer);
		const len = bytes.byteLength;
		for (let i = 0; i < len; i++) {
			binary += String.fromCharCode(bytes[i]);
		}
		return window.btoa(binary);
	};
	const programFeatures = [
		{
			id: 1,
			image:
				'https://akm-img-a-in.tosshub.com/aajtak/images/story/202404/660a1d7432d84-how-to-enhance-your-ethnic-look-with-earrings-01352970-16x9.jpg?size=316:179',
			title:
				'इंडियन आउटफिट में चार चांद लगा देंगे ये तरीके, ईयरिंग्स सिलेक्ट करने में करेंगे मदद',
			description:
				'Lorem ipsum dolor sit amet, conse ctetur adipisc amus ac iaculis arcu.'
		},
		{
			id: 2,
			image:
				'https://akm-img-a-in.tosshub.com/aajtak/images/story/202403/6606748b6efbb-20240329-29580239-16x9.jpg?size=316:179',
			title:
				'दूध, दही और पनीर का ज्यादा सेवन इनके लिए हो सकता है खतरनाक, ध्यान रखें ये एक बात',
			description:
				'Pellentesque sagittis placerat mollii purus onvallis venenatis asapien.'
		},
		{
			id: 3,
			image:
				'https://akm-img-a-in.tosshub.com/aajtak/images/story/202403/6606298a82246-mature-woman-waking-up-through-alarm-ring-on-smart-phone-in-bedroom-184916243-16x9.jpg?size=316:179',
			title: '5 तरीके जो बेहतर नींद में करेंगे मदद, तरोताजा होकर उठेंगे सुबह',
			description:
				'Integer ultricies lorem nec erat fau euismod ipsum nislnec leo iaculis.'
		},
		{
			id: 4,
			image:
				'https://akm-img-a-in.tosshub.com/aajtak/images/story/202312/odokdcof-sixteen_nine.jpg?size=316:179',
			title:
				'तलाक के पीछे होता है ये मुख्य कारण, मोटिवेशनल स्पीकर सिस्टर शिवानी ने बताया',
			description:
				'Duis sed sollicitudin narcu mi, faucibus in sapien non, auctor placerat arcu.'
		}
	];
	return (
		<section className="pb-lg-14 pb-6 mx-4">
			<Row>
				<Col xl={12} md={12} xs={12}>
					<div className=" mb-lg-4 mb-2 mt-3 ">
						<h2 className="display-3 fw-bold">Life Style</h2>
					</div>
				</Col>
			</Row>
			<Row className="align-items-center ">
				<Col xl={4} lg={4} md={12} xs={12}>
					{LifeStyleData?.slice(0, 1).map((item, index) => {
						const image = item?.thumbnail;
						const base64Image = arrayBufferToBase64(image?.data?.data);
						return (
							<div className="mb-6 mb-lg-0 mt-4" key={index}>
								<div className="mb-2">
									<Image
										src={`data:${item.contentType};base64,${base64Image}`}
										alt="image"
										className="img-fluid w-100 h-100 "
									/>
								</div>
								<div>
									<h1>{item.postTitle}</h1>
								</div>
							</div>
						);
					})}
				</Col>
				<Col xl={5} lg={5} md={12} xs={12}>
					<Row className="row-cols-1 ">
						{LifeStyleData?.slice(1, 5).map((item, index) => {
							const image = item?.thumbnail;
							const base64Image = arrayBufferToBase64(image?.data?.data);
							return (
								<Col key={index}>
									<div className="mb-xl-0 card-hover rounded-0 card ">
										<div className="d-lg-flex d-md-flex  gap-4">
											<Link to={`/marketing/blog/article-single/${item._id}`}>
												<div
													style={{
														width: '200px',
														height: '100px',
														overflow: 'hidden'
													}}
												>
													<Image
														src={`data:${item.contentType};base64,${base64Image}`}
														alt="webinar-1"
														className="w-100 h-100 img-fluid"
														style={{ objectFit: 'cover' }}
													/>
												</div>
											</Link>
											<h3>
												<Link
													to={`/marketing/blog/article-single/${item._id}`}
													className="text-inherit"
												>
													{item.postTitle}
												</Link>
											</h3>
										</div>
									</div>
									<hr />
								</Col>
							);
						})}
					</Row>
				</Col>
				<Col>
					{LifeStyleData?.slice(5, 6).map((item, index) => {
						const image = item?.thumbnail;
						const base64Image = arrayBufferToBase64(image?.data?.data);
						return (
							<div className="mb-6 mb-lg-0 " key={index}>
								<Link to={`/marketing/blog/article-single/${item._id}`}>
									<div className="mb-2">
										<Image
											src={`data:${item.contentType};base64,${base64Image}`}
											alt="image"
											className="img-fluid w-100 h-100 "
										/>
									</div>
									<div>
										<span className="text-danger">
											<CalendarCheck /> {item.date} | 3:04 pm
										</span>
										<h2>{item.postTitle}</h2>
										<p className="fs-3">{item.description}</p>
									</div>
								</Link>
							</div>
						);
					})}
					;
				</Col>
			</Row>
		</section>
	);
};

export default LifeStyle;
