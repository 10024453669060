import { configureStore } from '@reduxjs/toolkit';
import blogSlice from 'slices/BlogSlice'; // Import the blog slice
import categorySlice from 'slices/categorySlice'; // Import the category slice

const store = configureStore({
	reducer: {
		blog: blogSlice,
		category: categorySlice
	}
});

export default store;
