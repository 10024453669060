// import node module libraries
import React from 'react';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const BlogCard = ({ item }) => {
	const arrayBufferToBase64 = (buffer) => {
		let binary = '';
		const bytes = new Uint8Array(buffer);
		const len = bytes.byteLength;
		for (let i = 0; i < len; i++) {
			binary += String.fromCharCode(bytes[i]);
		}
		return window.btoa(binary);
	};
	const image = item?.thumbnail;
	const base64Image = arrayBufferToBase64(image?.data?.data);
	const CategoryColors = (category) => {
		switch (category) {
			case 'Courses':
				return 'success';
			case 'Tutorial':
				return 'primary';
			case 'Workshop':
				return 'warning';
			case 'Company':
				return 'info';
			default:
				return 'primary';
		}
	};

	return (
		<Card className="mb-4 shadow-lg">
			<Link to={`/marketing/blog/article-single/${item._id}`}>
				<Card.Img
					variant="top"
					src={`data:${item.contentType};base64,${base64Image}`}
					className="rounded-top-md img-fluid"
				/>
			</Link>
			{/* Card body  */}
			<Card.Body>
				<h3>
					<Link
						to={`/marketing/blog/article-single/${item._id}`}
						className="text-inherit"
					>
						{item?.postTitle?.split(' ').slice(0, 10).join(' ')}
						{item?.postTitle?.split(' ').length > 10 ? '...' : ''}
					</Link>
				</h3>
				<p>
					{item?.description?.split(' ').slice(0, 15).join(' ')}
					{item?.description?.split(' ').length > 15 ? '...' : ''}
				</p>
				{/*  Media content  */}
				{/* <Row className="align-items-center g-0 mt-4">
					<Col xs="auto">
						<Image
							src={item.authorimage}
							alt=""
							className="rounded-circle avatar-sm me-2"
						/>
					</Col>
					<Col className="col lh-1">
						<h5 className="mb-1">{item.authorname}</h5>
						<p className="fs-6 mb-0">{item.postedon}</p>
					</Col>
					<Col xs="auto">
						<p className="fs-6 mb-0">{item.readinglength} Min Read</p>
					</Col>
				</Row> */}
			</Card.Body>
		</Card>
	);
};

// Typechecking With PropTypes
BlogCard.propTypes = {
	item: PropTypes.object.isRequired
};

export default BlogCard;
