import React, { Fragment, useMemo, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import {
	Trash,
	MoreVertical,
	Edit,
	Move,
	Copy,
	ToggleLeft,
	ToggleRight
} from 'react-feather';

// Import required custom components
import TanstackTable from 'components/elements/advance-table/TanstackTable';
import Checkbox from 'components/elements/advance-table/Checkbox';
import DotBadge from 'components/elements/bootstrap/DotBadge';
import toast, { Toaster } from 'react-hot-toast';
import { deleteBlog, fetchBlogs, updatePostStatus } from 'slices/BlogSlice';
import { fetchCategories } from 'slices/categorySlice';
import { useDispatch, useSelector } from 'react-redux';

const PostsTable = ({ table_data }) => {
	const dispatch = useDispatch();
	const navigate = useNavigate(); // Use useNavigate instead of useHistory
	const categories = useSelector((state) => state.category.data.categories);

	useEffect(() => {
		dispatch(fetchCategories());
	}, [dispatch]);

	const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
		<Link
			to=""
			ref={ref}
			onClick={(e) => {
				e.preventDefault();
				onClick(e);
			}}
			className="btn-icon btn btn-ghost btn-sm rounded-circle"
		>
			{children}
		</Link>
	));

	const handleDelete = (postId) => {
		dispatch(deleteBlog(postId))
			.then(() => {
				toast.success('Category deleted successfully');
			})
			.catch((error) => {
				console.error('Error deleting category:', error);
				toast.error('An error occurred while deleting the category.');
			});
	};

	const handleEdit = (postId) => {
		navigate(`/cms/add-new-post?postId=${postId}`);
	};
	const handleStatusChange = (postId, status) => {
		dispatch(updatePostStatus({ postId, status }))
			.then((response) => {
				toast.success(`Post ${status} successfully`);
			})
			.catch((error) => {
				console.error(`Error updating post status to ${status}:`, error);
				toast.error(
					`An error occurred while ${
						status === 'published' ? 'publishing' : 'unpublishing'
					} the post.`
				);
			});
	};

	const ActionMenu = ({ post }) => {
		return (
			<Dropdown>
				<Dropdown.Toggle as={CustomToggle}>
					<MoreVertical size="15px" className="text-secondary" />
				</Dropdown.Toggle>
				<Dropdown.Menu align="end">
					<Dropdown.Header>SETTINGS</Dropdown.Header>
					<Dropdown.Item eventKey="1" onClick={() => handleEdit(post._id)}>
						<Edit size="15px" className="dropdown-item-icon" /> Edit
					</Dropdown.Item>
					<Dropdown.Item eventKey="2">
						<Move size="15px" className="dropdown-item-icon" /> Move
					</Dropdown.Item>
					<Dropdown.Item eventKey="3">
						<Copy size="15px" className="dropdown-item-icon" /> Copy
					</Dropdown.Item>
					<Dropdown.Item
						eventKey="4"
						onClick={() => handleStatusChange(post._id, 'Published')}
					>
						<ToggleLeft size="15px" className="dropdown-item-icon" /> Publish
					</Dropdown.Item>
					<Dropdown.Item
						eventKey="5"
						onClick={() => handleStatusChange(post._id, 'Draft')}
					>
						<ToggleRight size="15px" className="dropdown-item-icon" /> Unpublish
					</Dropdown.Item>
					<Dropdown.Item eventKey="6" onClick={() => handleDelete(post._id)}>
						<Trash size="15px" className="dropdown-item-icon" /> Delete
					</Dropdown.Item>
				</Dropdown.Menu>
			</Dropdown>
		);
	};

	const categoryMap = useMemo(() => {
		const map = {};
		categories.forEach((category) => {
			map[category._id] = category.catName;
		});
		return map;
	}, [categories]);

	const columns = useMemo(
		() => [
			{
				id: 'select',
				header: ({ table }) => (
					<Checkbox
						{...{
							checked: table.getIsAllRowsSelected(),
							indeterminate: table.getIsSomeRowsSelected(),
							onChange: table.getToggleAllRowsSelectedHandler()
						}}
					/>
				),
				cell: ({ row }) => (
					<div className="px-1">
						<Checkbox
							{...{
								checked: row.getIsSelected(),
								disabled: !row.getCanSelect(),
								indeterminate: row.getIsSomeSelected(),
								onChange: row.getToggleSelectedHandler()
							}}
						/>
					</div>
				)
			},
			{
				accessorKey: 'postTitle',
				header: 'Post',
				cell: ({ getValue }) => (
					<h5 className="mb-0">
						<Link to="#" className="text-inherit">
							{getValue()}
						</Link>
					</h5>
				)
			},
			{
				accessorKey: 'category',
				header: 'Category',
				cell: ({ getValue }) => (
					<Link to="#" className="text-inherit">
						{categoryMap[getValue()]}
					</Link>
				)
			},
			{ accessorKey: 'date', header: 'Date' },
			{
				accessorKey: 'status',
				header: 'Status',
				cell: ({ getValue }) => {
					let value = getValue().toLowerCase();
					return (
						<Fragment>
							<DotBadge
								bg={
									value === 'draft'
										? 'warning'
										: value === 'published'
											? 'success'
											: value === 'scheduled'
												? 'info'
												: value === 'deleted'
													? 'danger'
													: ''
								}
							></DotBadge>
							{value.charAt(0).toUpperCase() + value.slice(1)}
						</Fragment>
					);
				}
			},
			{
				accessorKey: 'shortcutmenu',
				header: '',
				cell: ({ row }) => <ActionMenu post={row.original} />
			}
		],
		[categoryMap]
	);

	const data = useMemo(() => table_data, [table_data]);

	return (
		<>
			<TanstackTable
				data={data}
				columns={columns}
				filter={true}
				filterPlaceholder="Search Course"
				pagination={true}
			/>
			<Toaster />
		</>
	);
};

export default PostsTable;
